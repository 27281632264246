<template>
  <div class="about">
    <h1>This is an about page</h1>
    <p>{{test}}</p>
  </div>
</template>
<script>

export default ({
  data:function(){return {test:11}},
  created:function(){
     this.$parent.middle = 2; 
    return;
  },
  setup() {
    console.log(1)
  },
})
</script>
